import get from 'lodash/get';
import { GetServerSideProps } from 'next';
import { FunctionComponent, useState } from 'react';

import { fetchPageContext, PageContextOptions } from 'core/services/context';
import { CentralContextService } from 'core/services/context/central';
import { CommonPageContext, CentralPageContext } from 'core/services/context/context';
import { getQueryParamsWithUrl } from 'core/utils/filters/search-filter';

import { CentralPage as CentralPageContent } from 'components/central-page/central-page';
import { Page } from 'components/page/page';

interface CentralPageProps {
  context: {
    common: CommonPageContext;
    extra: CentralPageContext;
  };
}

const CentralPage: FunctionComponent<CentralPageProps> = ({ context, context: { common } }) => {
  const [isTransparentHead, setIsTransparentHead] = useState(true);

  return (
    <Page ctx={common} isCentral isTransparentHead={isTransparentHead}>
      <CentralPageContent
        context={context}
        isTransparentHead={isTransparentHead}
        setIsTransparentHead={setIsTransparentHead}
      />
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res } = context;
  const host = process.env.NEXT_PUBLIC_MAIN_HOST || '';
  const correlationID = get(context, 'req.headers.x-request-id', '') as string;
  const cookies = get(context, 'req.headers.cookie', '');
  const query = getQueryParamsWithUrl(context);

  const options: PageContextOptions<CentralPageContext> = {
    path: '/',
    host,
    correlationID,
    cookies,
    authRequired: false,
    query,
    extra: (accessToken: Optional<string>): Promise<CentralPageContext> => {
      const centralContextService = new CentralContextService(host, correlationID, accessToken);
      return centralContextService.fetchContext(cookies);
    }
  };

  const pageContext = await fetchPageContext<CentralPageContext>(options);

  if (pageContext) {
    if (pageContext.common['set-cookie']) {
      pageContext.common['set-cookie'].map((cookie) => {
        res.setHeader('Set-Cookie', cookie);
      });
    }
  }

  return {
    props: {
      context: pageContext
    }
  };
};

export default CentralPage;
